import Head from 'next/head';
import { useRouter } from 'next/router';
import { useContext, useEffect, useState } from 'react';

import HeaderFooterContext from '@/context/HeaderFooterContext';
import Enkeys from '@/keys/enkeys';
import Frkeys from '@/keys/Frkeys';

import Footer from '../footer';
import Header from '../header';

const Layout = ({ children }: any) => {
  const { locale, pathname }: any = useRouter();
  const [isMounted, setMount] = useState(true);
  const { isShowHeaderFooter, isShowSelfHeader } =
    useContext(HeaderFooterContext);
  const router = useRouter();

  const urlPath = router?.asPath.split('/');

  /* eslint-disable no-param-reassign */
  const generateFrPath = () => {
    let frPath = '';
    urlPath.forEach((el: any) => {
      if (el && el !== 'en') {
        frPath += '/';
        el = Frkeys[el] === undefined ? el : Frkeys[el];
        frPath += el;
      }
    });
    return frPath;
  };
  const generateEnPath = () => {
    let enPath = '';
    urlPath.forEach((el: any) => {
      if (el && el !== 'fr') {
        enPath += '/';
        el = Enkeys[el] === undefined ? el : Enkeys[el];
        enPath += el;
      }
    });
    return enPath;
  };
  useEffect(() => {
    setMount(true);
  }, [locale]);
  return (
    <>
      <Head>
        <link
          key="en"
          rel="alternate"
          href={`${process.env.NEXT_PUBLIC_SITE_URL_ORIGIN}/en${generateEnPath()}/`}
          hrefLang="en"
        />
        <link
          key="fr"
          rel="alternate"
          href={`${process.env.NEXT_PUBLIC_SITE_URL_ORIGIN}/fr${generateFrPath()}/`}
          hrefLang="fr"
        />
      </Head>
      <div
        className={
          pathname === '/' ||
          pathname === '/self-storage/residential' ||
          pathname === '/self-storage/moving' ||
          pathname === '/self-storage/business' ||
          pathname === '/self-storage/car-storage' ||
          pathname === '/self-storage/student-storage' ||
          pathname === '/self-storage/climate-controlled-storage' ||
          pathname === '/self-storage/boat-storage' ||
          pathname === '/self-storage/motorcycle-storage' ||
          pathname === '/self-storage/recretional-storage' ||
          pathname === '/self-storage/rv-storage' ||
          pathname === '/self-storage/truck-storage'
            ? `layout sv-wrapper ${locale === 'fr' ? 'french-language' : ''}`
            : `layout sv-wrapper sticky_navigation ${
                locale === 'fr' ? 'french-language' : ''
              }`
        }
      >
        {isShowSelfHeader && <Header />}
        {isMounted ? children : null}
      </div>
      {isShowHeaderFooter && <Footer />}
    </>
  );
};

export default Layout;
